<template>
    <div style="width: 100%">
        <div class="background_class">
            <div class="message">
                <span class="title">企业认证</span>
                <!-- 个人登录 -->
                <div v-if="loginId == 'personal'">
                    <div class="body">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <div class="content">
                            <p>您当前登录的是个人身份</p>
                            <p class="enterprise" @click="showEntDialog">
                                立即认证
                            </p>
                        </div>
                    </div>
                    <div class="inform">
                        <div style="height: 120px">
                            <div
                                style="
                                    color: #f46144;
                                    font-size: 14px;
                                    font-weight: 700;
                                "
                            >
                                关于企业认证
                            </div>
                            <div style="display: flex">
                                <div style="width: 390px; font-size: 14px">
                                    <p style="color: #333333">
                                        您可以新申请认证一家企业
                                        （需要准备材料）
                                    </p>
                                    <div style="color: #666666">
                                        <div>1.与营业执照一致的企业名称</div>
                                        <div style="margin-top: 4px">
                                            2.营业执照影印件（营业执照复印件必须加盖红色公章）
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 390px; font-size: 14px">
                                    <p style="color: #333333">
                                        您也可以申请加入已通过认证的企业
                                    </p>
                                    <div style="color: #666666">
                                        <div>1.一家已经通过认证的企业名称</div>
                                        <div style="margin-top: 4px">
                                            2.申请加入该企业，并等待企业管理员审核
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 企业登录 -->
                <div v-else>
                    <div class="company-inform">
                        <div
                            style="
                                margin-bottom: 16px;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <span style="font-size: 14px; margin-right: 16px">
                                认证状态
                            </span>
                            <el-input
                                v-model="getCampany.Isaccess"
                                readonly
                                class="input-isaccess"
                                style="width: 300px"
                            ></el-input>
                        </div>
                        <div
                            style="
                                margin-bottom: 16px;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <span style="font-size: 14px; margin-right: 16px">
                                企业名称
                            </span>
                            <el-input
                                v-model="getCampany.Cname"
                                readonly
                                class="input-another"
                                style="width: 300px"
                            ></el-input>
                        </div>
                        <div
                            style="
                                margin-bottom: 16px;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <span style="font-size: 14px; margin-right: 16px">
                                管理员名
                            </span>
                            <el-input
                                v-model="getCampany.Uname"
                                readonly
                                class="input-another"
                                style="width: 300px"
                            ></el-input>
                        </div>
                        <!-- <div style="margin-bottom:16px; display:flex; align-items:center">
                      <span style="font-size:14px; margin-right:16px">企业ID</span>
                      <el-input v-model="getCampany.companyID" readonly class="input-another" style="width: 300px"></el-input>
                    </div> -->
                        <div
                            style="
                                margin-bottom: 16px;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <span style="font-size: 14px; margin-right: 16px">
                                企业地址
                            </span>
                            <el-input
                                v-model="getCampany.companyAddress"
                                readonly
                                class="input-another"
                                type="textarea"
                                style="width: 300px"
                            ></el-input>
                        </div>
                        <div
                            style="
                                margin-bottom: 24px;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <span style="font-size: 14px; margin-right: 16px">
                                企业电话
                            </span>
                            <el-input
                                v-model="getCampany.Cphone"
                                readonly
                                class="input-another"
                                style="width: 300px"
                            ></el-input>
                        </div>
                        <div
                            style="
                                width: 386px;
                                display: flex;
                                justify-content: center;
                            "
                        >
                            <el-button type="primary" disabled>
                                注销企业
                            </el-button>
                            <el-button type="button" @click="showEntDialog">
                                认证其他企业
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sendCompany-dialog
            v-if="entDialogVisible"
            ref="sendCompanyDialog"
        ></sendCompany-dialog>
        <loginTimeout-dialog
            v-if="loginTimeoutDialogVisible"
            ref="loginTimeoutDialog"
        ></loginTimeout-dialog>
    </div>
</template>
<script>
import sendCompanyDialog from "../common/sendCompany";
import loginTimeoutDialog from "../common/LoginTimeout";
import { getCompanyInfoByName } from "@/api";
import { apiCallback } from "../../../../utils";
export default {
    data() {
        return {
            imageUrl: require("@/assets/img/entcompany.png"),
            entDialogVisible: false,
            loginTimeoutDialogVisible: false,
            loginId: "", //登录身份
            getCampany: {}, //登录身份为企业，企业信息
        };
    },
    components: {
        sendCompanyDialog,
        loginTimeoutDialog,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            window.sessionStorage.setItem("activePath", "/user/ent-company");
            this.loginId = this.$store.state.user.loginId;
            if (this.loginId != "personal") {
                this.getCompanyInfo();
            }
        },
        showEntDialog() {
            this.entDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.sendCompanyDialog.init();
            });
        },
        getCompanyInfo() {
            var that = this;
            var data = {};
            data.cname = this.loginId;
            getCompanyInfoByName(data)
                .then((res) => {
                    const data = apiCallback(res);
                    that.getCampany = {};
                    if (data) {
                        var company = data;
                        that.getCampany.Isaccess =
                            company.isaccess == 1 ? "认证成功" : "";
                        that.getCampany.Cname = company.cname;
                        that.getCampany.Uname = company.uname;
                        // that.getCampany.companyID = ''
                        that.getCampany.companyAddress = company.address;
                        that.getCampany.Cphone = company.cphone;
                    } else {
                        that.getCampany.Isaccess = "";
                        that.getCampany.Cname = "";
                        // that.getCampany.companyID = ''
                        that.getCampany.companyAddress = "";
                        that.getCampany.Cphone = "";
                    }
                })
                .catch((err) => console.err(err));
        },
    },
};
</script>

<style lang="stylus" scoped>
/deep/input::-webkit-input-placeholder {
  color: #778899;
}

.background_class {
  display: flex;
  background-color: #ffffff;
  height: 800px;
  width: auto;
  justify-content: flex-start;
}

.message {
  /* margin: 30px 0 8px 15px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* font-size: 16px; */
  /* justify-content: center; */
}


.title {
    font-size: 1.68rem;
    font-weight: 700;
  padding: 1.68rem;
  background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
    color:white;
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 50px;
  font-size: 12px;
  color: #989898;
}

.avatar {
  width: 200px;
  height: 160px;
  display: block;
}

.content {
  display: flex;
}

.enterprise {
  /* margin-left: 10px; */
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: rgb(85, 114, 253);
  user-select: none;
}

.inform {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 50px;
  height: 120px;
  font-size: 12px;
  color: #989898;
  background-color: #fff9e6;
}

.company-inform {
  padding: 20px;
  width: 386px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.input-isaccess /deep/.el-input__inner {
  color: #30bf78 !important;
}

.input-another /deep/.el-input__inner {
  color: #333 !important;
}
</style>
